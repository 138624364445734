.slider {
  padding: 1px 0;
  box-sizing: border-box;
  touch-action: none;

  input[type="range"] {
    &::-webkit-slider-thumb {
      width: 24px;
      height: 24px;

      &:hover {
        box-shadow: 0 0 0 6px rgb(51 0 255 / 20%);
      }

      @apply bg-blue-585;
    }

    &::-moz-range-thumb {
      width: 24px;
      height: 24px;

      @apply bg-blue-585;
    }
  }
}

.slider:focus {
  outline: none;
}

.slider::-webkit-slider-thumb {
  appearance: none;
  border: 7px solid #637da1;
  border-radius: 18px;
  background: #fff;
  transition: box-shadow 0.5s;
}

.slider::-moz-range-thumb {
  border: 2px solid #637da1;
  border-radius: 20px;
  background: #fff;
  transition: box-shadow 0.5s;
}

.slider::-ms-thumb {
  border: 2px solid #637da1;
  border-radius: 18px;
  background: #fff;
  transition: box-shadow 0.5s;
}
